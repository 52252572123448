export interface Book {
  number: number,
  name: String,
  background: String,
  drip: String,
  special: Boolean
}

export enum GameState {
  InProgress,
  Win,
  Loss
}

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
  genie: number
}
