import { GameStats } from '../../types';

type Props = {
  gameStats: GameStats
}

function StatItem({
  label,
  value,
}: {
  label: string
  value: string | number
}) {
  return (
    <div className="items-center justify-center m-1 w-1/4 dark:text-white">
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-xs">{label}</div>
    </div>
  );
}

export function StatBar({ gameStats }: Props) {
  return (
    <div className="flex justify-center my-2">
      <StatItem label="Total Tries" value={gameStats.totalGames} />
      <StatItem label="Success Rate" value={`${gameStats.successRate}%`} />
      <StatItem label="Current Streak" value={gameStats.currentStreak} />
      <StatItem label="Best Streak" value={gameStats.bestStreak} />
    </div>
  );
}
