import { BaseModal } from './BaseModal';

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export function WishModal({ isOpen, handleClose }: Props) {
  return (
    <BaseModal title="Our Apologies" isOpen={isOpen} handleClose={handleClose}>
      <p>
        It looks like we made a mistake! We had the colors wrong on today's goosebumple. We've called in Clarissa, the Crystal Woman, 
        to grant you a wish. We've erased your missed attempt, or at least tried to, and you can have another go. But, just like in the book, 
        there's no telling what will happen by making this wish.
      </p>
    </BaseModal>
  );
}
