export const BOOKS = [
  {
    number: 55,
    name: 'The Blob That Ate Everyone',
    background: '#ecdeb7',
    drip: '#2b4d65',
    special: false,
  },
  {
    number: 18,
    name: 'Monster Blood II',
    background: '#f9b53e',
    drip: '#288547',
    special: false,
  },
  {
    number: 2,
    name: 'Stay Out of the Basement',
    background: '#bfdf42',
    drip: '#502083',
    special: false,
  },
  {
    number: 46,
    name: 'How to Kill a Monster',
    background: '#f87ea3',
    drip: '#15698e',
    special: false,
  },
  {
    number: 25,
    name: 'Attack of the Mutant',
    background: '#fcfcfc',
    drip: '#f06425',
    special: false,
  },
  {
    number: 54,
    name: "Don't Go to Sleep!",
    background: '#d6f269',
    drip: '#a939a0',
    special: false,
  },
  {
    number: 59,
    name: 'The Haunted School',
    background: '#f0ca40', // #d1c63f
    drip: '#82bf3d', // #131507
    special: false,
  },
  {
    number: 23,
    name: 'Return of the Mummy',
    background: '#c9cc3d',
    drip: '#61b9e1',
    special: false,
  },
  {
    number: 9,
    name: 'Welcome to Camp Nightmare',
    background: '#a8dfe6',
    drip: '#7e62b6',
    special: false,
  },
  {
    number: 50,
    name: 'Calling All Creeps!',
    background: '#ec50a0',
    drip: '#555861',
    special: false,
  },
  {
    number: 56,
    name: 'The Curse of Camp Cold Lake',
    background: '#fbfcdd',
    drip: '#171741',
    special: false,
  },
  {
    number: 10,
    name: 'The Ghost Next Door',
    background: '#eaa761',
    drip: '#5d6012',
    special: false,
  },
  {
    number: 37,
    name: 'The Headless Ghost',
    background: '#f74e8c',
    drip: '#4c2851',
    special: false,
  },
  {
    number: 52,
    name: 'How I Learned to Fly',
    background: '#dad2dd',
    drip: '#9e481e',
    special: false,
  },
  {
    number: 6,
    name: "Let's Get Invisible!",
    background: '#dbdbdd',
    drip: '#113c29',
    special: false,
  },
  {
    number: 62,
    name: 'Monster Blood IV',
    background: '#fc8aa3',
    drip: '#455bb1',
    special: false,
  },
  {
    number: 45,
    name: 'Ghost Camp',
    background: '#cd4c24',
    drip: '#140c05',
    special: false,
  },
  {
    number: 13,
    name: 'Piano Lessons Can Be Murder',
    background: '#79bebb',
    drip: '#4f1221',
    special: false,
  },
  {
    number: 38,
    name: 'The Abominable Snowman of Pasadena',
    background: '#88c2d5',
    drip: '#8e4f2e',
    special: false,
  },
  {
    number: 27,
    name: 'A Night in Terror Tower',
    background: '#f7eb7e',
    drip: '#207ac5',
    special: false,
  },
  {
    number: 3,
    name: 'Monster Blood',
    background: '#f3c01d',
    drip: '#1d399e',
    special: false,
  },
  {
    number: 1,
    name: 'Welcome to Dead House',
    background: '#eee1df',
    drip: '#b00162',
    special: false,
  },
  {
    number: 34,
    name: 'Revenge of the Lawn Gnomes',
    background: '#f6fa81',
    drip: '#eb602d',
    special: false,
  },
  {
    number: 49,
    name: 'Vampire Breath',
    background: '#eaa339',
    drip: '#282661',
    special: false,
  },
  {
    number: 14,
    name: 'The Werewolf of Fever Swamp',
    background: '#c7ea4a',
    drip: '#513a90',
    special: false,
  },
  {
    number: 60,
    name: 'Werewolf Skin',
    background: '#f8b86e',
    drip: '#264d89',
    special: false,
  },
  {
    number: 57,
    name: 'My Best Friend Is Invisible',
    background: '#f3ea8b',
    drip: '#e17812',
    special: false,
  },
  {
    number: 40,
    name: 'Night of the Living Dummy III',
    background: '#eae2b4',
    drip: '#535e21',
    special: false,
  },
  {
    number: 5,
    name: "The Curse of the Mummy's Tomb",
    background: '#d9c8db',
    drip: '#084960',
    special: false,
  },
  {
    number: 36,
    name: 'The Haunted Mask II',
    background: '#f99739',
    drip: '#224797',
    special: false,
  },
  {
    number: 4,
    name: 'Say Cheese and Die!',
    background: '#b5c2ca',
    drip: '#b21f1d',
    special: false,
  },
  {
    number: 51,
    name: 'Beware, the Snowman',
    background: '#aec0ee',
    drip: '#d91f10',
    special: false,
  },
  {
    number: 58,
    name: 'Deep Trouble II',
    background: '#dcebe4',
    drip: '#b64187',
    special: false,
  },
  {
    number: 29,
    name: 'Monster Blood III',
    background: '#fae3c6',
    drip: '#0b271f',
    special: false,
  },
  {
    number: 15,
    name: "You Can't Scare Me!",
    background: '#94e4fe',
    drip: '#d9952b',
    special: false,
  },
  {
    number: 47,
    name: 'Legend of the Lost Legend',
    background: '#d6eb5d',
    drip: '#833126',
    special: false,
  },
  {
    number: 16,
    name: 'One Day at HorrorLand',
    background: '#bd8eb9',
    drip: '#2ea5af',
    special: false,
  },
  {
    number: 42,
    name: 'Egg Monsters from Mars',
    background: '#f7ec04',
    drip: '#a51d3e',
    special: false,
  },
  {
    number: 24,
    name: 'Phantom of the Auditorium',
    background: '#f495b5',
    drip: '#18112d',
    special: false,
  },
  {
    number: 31,
    name: 'Night of the Living Dummy II',
    background: '#f789b3',
    drip: '#00bb51',
    special: false,
  },
  {
    number: 33,
    name: 'The Horror at Camp Jellyjam',
    background: '#abadc4',
    drip: '#229386',
    special: false,
  },
  {
    number: 44,
    name: 'Say Cheese and Die — Again!',
    background: '#c8b8c5',
    drip: '#50a770',
    special: false,
  },
  {
    number: 35,
    name: 'A Shocker on Shock Street',
    background: '#fbe870',
    drip: '#96a69a',
    special: false,
  },
  {
    number: 48,
    name: "Attack of the Jack O' Lanterns",
    background: '#fbfd79',
    drip: '#36ae35',
    special: false,
  },
  {
    number: 61,
    name: 'I Live in Your Basement!',
    background: '#fadb0f',
    drip: '#55155a',
    special: false,
  },
  {
    number: 11,
    name: 'The Haunted Mask',
    background: '#fe9d1f',
    drip: '#047aa5',
    special: false,
  },
  {
    number: 22,
    name: 'Ghost Beach',
    background: '#d6d6b4',
    drip: '#558e85',
    special: false,
  },
  {
    number: 17,
    name: "Why I'm Afraid of Bees",
    background: '#f9ed78',
    drip: '#f16729',
    special: false,
  },
  {
    number: 8,
    name: 'The Girl Who Cried Monster',
    background: '#cde9dd',
    drip: '#6a5ab3',
    special: false,
  },
  {
    number: 19,
    name: 'Deep Trouble',
    background: '#fcdfab',
    drip: '#353b8f',
    special: false,
  },
  {
    number: 30,
    name: 'It Came from Beneath the Sink!',
    background: '#d9d708',
    drip: '#651813',
    special: false,
  },
  {
    number: 39,
    name: 'How I Got My Shrunken Head',
    background: '#e7ec4a',
    drip: '#0c2b23',
    special: false,
  },
  {
    number: 12,
    name: 'Be Careful What You Wish For',
    background: '#c2aad4',
    drip: '#911d85',
    special: false,
  },
  {
    number: 43,
    name: 'The Beast from the East',
    background: '#ced8e2',
    drip: '#2d1786',
    special: false,
  },
  {
    number: 53,
    name: 'Chicken Chicken',
    background: '#b3e8f7',
    drip: '#130941',
    special: false,
  },
  {
    number: 7,
    name: 'Night of the Living Dummy',
    background: '#d8d02d',
    drip: '#c51018',
    special: false,
  },
  {
    number: 41,
    name: 'Bad Hare Day',
    background: '#eae8e9',
    drip: '#c98f00',
    special: false,
  },
  {
    number: 32,
    name: 'The Barking Ghost',
    background: '#e5e6e8',
    drip: '#eb1e27',
    special: false,
  },
  {
    number: 21,
    name: 'Go Eat Worms!',
    background: '#d0e7cb',
    drip: '#983a73',
    special: false,
  },
  {
    number: 28,
    name: 'The Cuckoo Clock of Doom',
    background: '#efda4d',
    drip: '#16a2d6',
    special: false,
  },
  {
    number: 20,
    name: 'The Scarecrow Walks at Midnight',
    background: '#e8ca3a',
    drip: '#972436',
    special: false,
  },
  {
    number: 26,
    name: 'My Hairiest Adventure',
    background: '#87c8c0',
    drip: '#c61f32',
    special: false,
  },
];
