import { BaseModal } from './BaseModal';

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export function SettingsModal({ isOpen, handleClose }: Props) {
  return (
    <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
      <p>Here is the content</p>
    </BaseModal>
  );
}
