import { useEffect, useState } from 'react';
import './App.scss';
import { Book, GameState, GameStats } from './types';
import {
  isWinningBook,
  solution,
} from './lib/books';
import {
  addStatsForCompletedGame, grantAWish, loadGameStateFromLocalStorage, loadStats, saveGameStateToLocalStorage, saveStatsToLocalStorage,
} from './localStorage';
import { SettingsModal } from './components/modals/SettingsModal';
import { StatsModal } from './components/modals/StatsModal';
import { InfoModal } from './components/modals/InfoModal';
import { BOOK_RESET_NUMBER, MAX_GUESSES, MAX_WISHES, WELCOME_INFO_MODAL_MS } from './constants/settings';
import { Header } from './components/Header';
import { InProgress } from './components/states/InProgress';
import { GameOver } from './components/states/GameOver';
import { AppNotification } from './components/AppNotification';
import { WishModal } from './components/modals/WishModal';

function App() {
  const needsTheGenie = (loaded: GameStats) => {
    if(loaded?.genie >= 1) {
      return false;
    }
    else if(solution.number === BOOK_RESET_NUMBER && gameState === GameState.Loss) {
      return true;
    }

    return false
  }

  const [gameState, setGameState] = useState<GameState>(GameState.InProgress);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isWishModalOpen, setIsWishModalOpen] = useState(false);
  const [genie, setGenie] = useState(false);

  const [stats, setStats] = useState(() => loadStats());

  const [notificationProps, setNotificationProps] = useState({
    shouldShow: false,
    title: 'Successfully Copied',
    message: 'Thanks for sharing Goosebumple!',
  });

  const [guesses, setGuesses] = useState<Book[]>(() => {
    const loaded = loadGameStateFromLocalStorage();

    if (loaded?.solution?.number !== solution.number) {
      return [];
    }

    const gameWasWon = loaded.guesses.some((g) => g.number === solution.number);
    if (gameWasWon) {
      setGameState(GameState.Win);
    }
    if (loaded.guesses.length >= MAX_GUESSES && !gameWasWon) {
      setGameState(GameState.Loss);
    }
    return loaded.guesses;
  });

  useEffect(() => {
    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true);
      }, WELCOME_INFO_MODAL_MS);
    }
  }, []);

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution });
    if(needsTheGenie(stats)) {
      setGenie(true);
    }
    else {
      saveStatsToLocalStorage({ ...stats, genie: MAX_WISHES });
      setStats(loadStats());
    }
  }, [guesses]);

  useEffect(() => {
    if(genie) {
      grantAWish();
      setGuesses([]);
      setGameState(GameState.InProgress);
      setIsWishModalOpen(true);
      setStats(loadStats());
    }
  }, [genie])

  const makeGuess = (guess: Book) => {
    setGuesses([
      ...guesses,
      (guess),
    ]);

    if (isWinningBook(guess)) {
      setStats(addStatsForCompletedGame(stats, guesses.length));
      setGameState(GameState.Win);
    } else if (guesses.length === MAX_GUESSES - 1) {
      setStats(addStatsForCompletedGame(stats, MAX_GUESSES + 1));
      setGameState(GameState.Loss);
    }
  };

  const handleShareToClipboard = () => {
    setNotificationProps({
      ...notificationProps,
      shouldShow: true,
    });
  };

  return (
    <div className={`App book-${solution?.number}`}>
      <div className="top">
        <Header
          solution={solution}
          handleStatClick={() => setIsStatsModalOpen(true)}
          handleInfoClick={() => setIsInfoModalOpen(true)}
          guesses={guesses}
          state={gameState}
        />
        {gameState == (GameState.InProgress)
          ? (
            <InProgress
              solution={solution}
              guesses={guesses}
              state={gameState}
              handleSelectChange={makeGuess}
            />
          )
          : (<GameOver guesses={guesses} solution={solution} state={gameState} handleShareToClipboard={handleShareToClipboard} />)}
      </div>
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        solution={solution}
        guesses={guesses}
        gameStats={stats}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <WishModal isOpen={isWishModalOpen} handleClose={() => setIsWishModalOpen(false)} />
      {notificationProps.shouldShow && (
        <AppNotification
          {...notificationProps}
          handleClose={() => (setNotificationProps({
            ...notificationProps,
            shouldShow: false,
          }))}
        />
      )}
    </div>
  );
}

export default App;
