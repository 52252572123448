import { BaseModal } from './BaseModal';
import BookSpine from '../BookSpine';
import { solution } from '../../lib/books';
import { GameState } from '../../types';

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export function InfoModal({ isOpen, handleClose }: Props) {
  const exampleBook = {
    number: 0,
    name: 'Example Book',
    background: '#fff',
    drip: '#333',
    special: false,
  };

  return (
    <BaseModal title="How to Play" isOpen={isOpen} handleClose={handleClose}>
      <p className="mb-4">
        Think you know your
        {' '}
        <span className="goosebump-font text-xl">Goosebumps</span>
        {' '}
        books? You'll have 5 guesses to prove it.
      </p>
      <p className="mb-4">
        The color themes are based on the covers of the original 62 books.
        In the future, we might include the "Tales to Give You Goosebumps" and "Give Yourself Goosebumps" series.
      </p>
      <p className="mb-4">
        After each incorrect guess, you'll see a hint letting you know if the correct
        answer is higher or lower in numerical order from your guess.
      </p>
      <BookSpine
        book={exampleBook}
        state={GameState.InProgress}
        solution={{ ...exampleBook, number: 1 }}
      />
      <br />
      <BookSpine
        book={{...exampleBook, number: 99}}
        state={GameState.InProgress}
        solution={{ ...exampleBook, number: -1 }}
      />
      <br />
      <div className="text-center flex justify-center items-center flex-col mt-4">
        <a className={`kofi-button py-2 px-3 rounded-lg items-center flex book-${solution.number}`} href="https://ko-fi.com/goosebumple" title="Support us on Ko-Fi" target="_blank" rel="noreferrer">
          <span className="kofitext svelte-1d3p4dy">
            <img src="https://storage.ko-fi.com/cdn/cup-border.png" alt="Ko-fi donations" className="mr-2 mb-1 inline w-5 animate-bounce" />
            Support us on Ko-Fi
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
        </a>
      </div>
    </BaseModal>
  );
}
