import { Book, GameState } from '../types';

interface Props {
  book: Book;
  solution: Book;
  state?: GameState;
}

const downArrow = '↓';
const upArrow = '↑';
const check = '✓';
const cross = '𐄂';

export default function BookSpine({ book, solution, state }: Props) {
  let icon = '';

  if (book.number == solution?.number) {
    icon = state === GameState.Win ? check : cross;
  } else {
    icon = book.number < solution.number ? upArrow : downArrow;
  }

  return (
    <div className={`spine book-${book.number} flipped drop-shadow-lg py-2`}>
      <div className="-rotate-90 drop-shadow-sm">
        #
        {book.number}
      </div>
      <div className="goosebump-font drop-shadow-sm hidden sm:block">Goosebumple</div>
      <div className="px-8 drop-shadow-sm">{book.name}</div>
      <div className="icon">{icon}</div>
    </div>
  );
}
