import { ChartBarIcon } from '@heroicons/react/solid';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { Book, GameState } from '../types';

interface Props {
  solution: Book;
  handleStatClick: () => void;
  handleInfoClick: () => void;
  guesses: Book[];
  state: GameState;
}

export function Header({
  solution, handleStatClick, handleInfoClick, guesses, state,
}: Props) {
  const round = state === GameState.InProgress ? guesses.length : 0;

  return (
    <header className="App-header px-4">
      <div className="flex justify-between items-center max-w-lg mx-auto my-6">
        <div>
          <ChartBarIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => handleStatClick()}
          />
        </div>

        <p className="tagline px-4">Guesser beware, you're in for a scare</p>

        <div>
          <QuestionMarkCircleIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => handleInfoClick()}
          />
        </div>
      </div>
      <h1 className={`inline-block text-5xl md:text-7xl book-${solution?.number}`}>
        Goosebumple
        <span className={`drop drop-${round}`} />
        <span className={`drop drop-${round}`} />
        <span className={`drop drop-${round}`} />
        <span className={`drop drop-${round}`} />
        <span className={`drop drop-${round}`} />
      </h1>
    </header>
  );
}
