function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function KofiButton() {
  return (
    <a className={`transition kofi-button py-2 px-3 rounded-lg items-center flex bg-slate-50 shadow-md book-${getRandomInt(61) + 1}`} href="https://ko-fi.com/goosebumple" title="Support us on Ko-Fi" target="_blank" rel="noreferrer">
      <span className="color-black">
        <img src="https://storage.ko-fi.com/cdn/cup-border.png" alt="Ko-fi donations" className="mr-2 mb-1 inline w-5 animate-bounce" />
        Support us on Ko-Fi
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
    </a>
  );
}
