import { BOOKS } from '../constants/booklist';
import { Book } from '../types';

export const isWinningBook = (book: Book | undefined) => solution.number === book?.number;

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date(2022, 3, 14).valueOf();
  const now = Date.now();
  const msInDay = 86400000;
  const index = Math.floor((now - epochMs) / msInDay);
  const nextday = (index + 1) * msInDay + epochMs;

  return {
    solution: (BOOKS[index % BOOKS.length]),
    solutionIndex: index,
    tomorrow: nextday,
  };
};

export const { solution, solutionIndex, tomorrow } = getWordOfDay();
