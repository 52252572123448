import { Transition } from '@headlessui/react';
import { useState } from 'react';
import BookSelect from '../../BookSelect';
import { BOOKS } from '../../constants/booklist';
import { Book, GameState } from '../../types';
import BookSpine from '../BookSpine';

type Props = {
  state: GameState;
  solution: Book;
  guesses: Book[];
  handleSelectChange: (guess: Book) => void;
}

export function InProgress({
  state, solution, guesses, handleSelectChange,
} : Props) {
  const [currentGuess, setCurrentGuess] = useState<{label: String, value: Number} | null>(null);

  const selectChanged = (newValue: { value: number; }) => {
    const newGuess = BOOKS.find((book) => book.number == newValue.value);

    if (newGuess !== undefined) {
      handleSelectChange(newGuess);
      setCurrentGuess(null);
    }
  };

  return (
    <div className="guesses px-4 max-w-2xl">
      <p className="mb-4 text-black opacity-30 font-bold">
        Guess
        {' '}
        {guesses.length + 1}
        {' '}
        / 5
      </p>
      {guesses.map((guess, i) => (
        <Transition
          enter="transition duration-500"
          enterFrom="scale-0"
          enterTo="scale-1"
          show
          appear
        >
          <BookSpine book={guess} solution={solution} key={i} state={state} />
        </Transition>
      ))}
      <div className="selection">
        <BookSelect
          items={BOOKS.slice().sort((a, b) => ((a.number > b.number) ? 1 : -1))}
          onChange={selectChanged}
          currentGuess={currentGuess}
          answer={solution}
        />
      </div>
    </div>
  );
}
