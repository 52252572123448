import Select from 'react-select';
import { Book } from './types';

interface Props {
  items: Book[];
  currentGuess: {value: Number, label: String} | null;
  onChange: Function;
  answer: Book;
}

export default function BookSelect({
  items, onChange, currentGuess, answer,
}: Props) {
  const customStyles = {
    option: (provided: any, _state: any) => ({
      ...provided,
      color: answer.drip,
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: `dashed 2px ${answer?.drip}`,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: answer?.drip,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: answer?.drip,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: answer?.drip,
    }),
  };

  return (
    <Select
      className="book-select"
      onChange={(newValue) => { onChange(newValue); }}
      value={currentGuess}
      placeholder="Which book had this color scheme?"
      menuPlacement="top"
      styles={customStyles}
      options={
        items.map((book) => ({ value: book.number, label: `#${book.number} - ${book.name}` }))
      }
    />
  );
}
