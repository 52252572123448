import { BaseModal } from './BaseModal';
import { Book, GameStats } from '../../types';
import { StatBar } from '../stats/StatBar';
import { Histogram } from '../stats/Histogram';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  solution: Book;
  guesses: Book[];
  gameStats: GameStats;
}

export function StatsModal({
  isOpen, handleClose, solution, gameStats, guesses,
}: Props) {
  return (
    <BaseModal title="Stats" isOpen={isOpen} handleClose={handleClose}>
      <StatBar gameStats={gameStats} />
      <Histogram
        gameStats={gameStats}
        numberOfGuessesMade={guesses.length}
      />
      <div className="text-center flex justify-center items-center flex-col ">
        <a className={`kofi-button py-2 px-3 rounded-lg items-center flex book-${solution.number}`} href="https://ko-fi.com/goosebumple" title="Support us on Ko-Fi" target="_blank" rel="noreferrer">
          <span className="kofitext svelte-1d3p4dy">
            <img src="https://storage.ko-fi.com/cdn/cup-border.png" alt="Ko-fi donations" className="mr-2 mb-1 inline w-5 animate-bounce" />
            Support us on Ko-Fi
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
        </a>
      </div>
    </BaseModal>
  );
}
