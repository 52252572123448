import { UAParser } from 'ua-parser-js';
import { solution, solutionIndex } from './books';
import { MAX_GUESSES } from '../constants/settings';
import { Book } from '../types';

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable'];
const parser = new UAParser();
const browser = parser.getBrowser();
const device = parser.getDevice();

export const shareStatus = (
  guesses: Book[],
  handleShareToClipboard: () => void,
) => {
  const textToShare = `#Goosebumple ${solutionIndex + 1} \n\n😱${
    generateEmojiGrid(guesses, getEmojiTiles())
  }\n\nhttps://goosebumple.com`;

  const shareData = { text: textToShare };

  let shareSuccess = false;

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData);
      shareSuccess = true;
    }
  } catch (error) {
    shareSuccess = false;
  }

  if (!shareSuccess) {
    navigator.clipboard.writeText(textToShare);
    handleShareToClipboard();
  }
};

export const generateEmojiGrid = (guesses: Book[], tiles: string[]) => [...Array(MAX_GUESSES)].map((x, i) => {
  if (i + 1 > guesses.length) return tiles[1];

  return guesses[i].number === solution.number ? tiles[0] : tiles[2];
}).join('');

const attemptShare = (shareData: object) => (
  // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
  browser.name?.toUpperCase().indexOf('FIREFOX') === -1
    && webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1
    && navigator.canShare
    && navigator.canShare(shareData)
    && navigator.share
);

const getEmojiTiles = () => {
  const tiles: string[] = [];
  tiles.push('🟩');
  tiles.push('⬜️');
  tiles.push('⬛');
  return tiles;
};
