import { Transition } from '@headlessui/react';
import { EyeIcon, EyeOffIcon, ShareIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import Countdown from 'react-countdown';
import { MAX_GUESSES } from '../../constants/settings';
import { tomorrow } from '../../lib/books';
import { shareStatus } from '../../lib/share';
import { Book, GameState } from '../../types';
import BookSpine from '../BookSpine';
import { KofiButton } from '../KofiButton';

type Props = {
  guesses: Book[];
  solution: Book;
  state: GameState;
  handleShareToClipboard: () => void;
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function GameOver({
  guesses, solution, state, handleShareToClipboard,
}: Props) {
  const [showGuesses, setShowGuesses] = useState(false);

  const guessBackground = (guess:number) => {
    const book = guesses[guess];

    if (book === undefined) return 'bg-white';
    if (book.number === solution.number) return `book-${book.number} flipped`;

    return `book-${solution.number} brightness-75`;
  };

  return (
    <Transition
          enter="transition duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-1"
          show
          appear
        >
      <div className='px-4'>
        <p className="mb-4 text-black opacity-30" title="Show / Hide Guesses" onClick={() => (setShowGuesses(!showGuesses))}>
          {(showGuesses
            ? (<EyeOffIcon className="h-6 w-6 inline cursor-pointer" />)
            : (<EyeIcon className="h-6 w-6 inline cursor-pointer" />)
          )}
        </p>
        <div className="guesses mb-4 max-w-2xl">
          {
            showGuesses
              ? (
                guesses.map((guess, i) => (
                  <BookSpine book={guess} solution={solution} key={i} state={state} />
                ))
              )
              : (<BookSpine book={solution} solution={solution} state={state} />)
          }

        </div>
        <div className="flex justify-center my-2 mt-8">
          {[...Array(MAX_GUESSES)].map((x, i) => <div className={`w-4 h-1 m-0.5 ${guessBackground(i)}`} />)}
        </div>
        {state == GameState.Win
          ? (
            <p className="py-1">
              You got today's
              {' '}
              <span className="goosebump-font text-xl">Goosebumple</span>
              {' '}
              in
              {' '}
              {guesses.length}
              {' '}
              {guesses.length == 1 ? 'guess' : 'guesses'}
              !
            </p>
          )
          : (
            <p className="py-1">
              You didn't get today's
              {' '}
              <span className="goosebump-font text-xl">Goosebumple</span>
              . Better luck next time!
            </p>
          )}

        <div className="flex justify-center items-center pt-3">
          <button
            type="button"
            className={`transition book-${getRandomInt(61) + 1} mr-4 rounded-md border border-transparent shadow-md px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm`}
            onClick={() => {
              shareStatus(
                guesses,
                handleShareToClipboard,
              );
            }}
          >
            Share
            {' '}
            <ShareIcon className="h-6 w-6 inline" />
          </button>
          <KofiButton />
        </div>
        <div>
          <div className="mt-5 sm:mt-6">
            <div>
              <h5>New Book In:</h5>
              <Countdown
                className="text-lg font-medium text-gray-900 dark:text-gray-100"
                date={tomorrow}
                daysInHours
              />
            </div>

          </div>
        </div>
      </div>
    </Transition>
  );
}
