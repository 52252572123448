import { Book, GameStats } from './types';
import { MAX_GUESSES, MAX_WISHES } from './constants/settings';

const gameStateKey = 'gameState';
const gameStatKey = 'gameStats';

type StoredGameState = {
  guesses: Book[]
  solution: Book
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  localStorage.setItem(gameStateKey, JSON.stringify(gameState));
};

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey);
  return state ? (JSON.parse(state) as StoredGameState) : null;
};

const defaultStats: GameStats = {
  winDistribution: Array.from(new Array(MAX_GUESSES), () => 0),
  gamesFailed: 0,
  currentStreak: 0,
  bestStreak: 0,
  totalGames: 0,
  successRate: 0,
  genie: 0,
};

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey);
  return stats ? (JSON.parse(stats) as GameStats) : null;
};

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats));
};

export const loadStats = () => loadStatsFromLocalStorage() || defaultStats;

const getSuccessRate = (gameStats: GameStats) => {
  const { totalGames, gamesFailed } = gameStats;

  return Math.round(
    (100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1),
  );
};

export const addStatsForCompletedGame = (
  gameStats: GameStats,
  count: number,
) => {
  // Count is number of incorrect guesses before end.
  const stats = { ...gameStats };

  stats.totalGames += 1;

  if (count >= MAX_GUESSES) {
    // A fail situation
    stats.currentStreak = 0;
    stats.gamesFailed += 1;
  } else {
    stats.winDistribution[count] += 1;
    stats.currentStreak += 1;

    if (stats.bestStreak < stats.currentStreak) {
      stats.bestStreak = stats.currentStreak;
    }
  }

  stats.successRate = getSuccessRate(stats);

  saveStatsToLocalStorage(stats);
  return stats;
};

export const grantAWish = () => {
  const stats = loadStats();

  stats.currentStreak = stats.bestStreak;  
  stats.gamesFailed = (stats.gamesFailed == 0) ? 0 : (stats.gamesFailed - 1);
  stats.totalGames = (stats.totalGames == 0) ? 0 : (stats.totalGames - 1);
  stats.genie = MAX_WISHES;

  saveStatsToLocalStorage(stats);
}
